import Chart from './Chart'
import _merge from 'lodash/merge'

class AreaChart extends Chart {
  constructor (ref, series, options) {
    super(ref, series)

    this.setOptions(this.areaChartOptions(options))

    this.init()
  }

  areaChartOptions ({ chart = {}, title, subtitle, tooltipFormatter, tooltip = {}, yAxisLabel, yAxisLabelRotation = -90, xAxis, yAxis = {}, legend = {}, color, plotOptions, noData = {} }) {
    if (tooltipFormatter) {
      tooltip.formatter = tooltipFormatter
    }

    return {
      chart: {
        type: 'areaspline',
        ...chart
      },
      color,
      legend,
      title: _merge({
        align: 'left',
        style: { fontSize: '1.25rem', fontWeight: '400' }
      }, title),
      subtitle: {
        text: subtitle,
        align: 'center'
      },
      yAxis: {
        // min: 0,
        title: {
          text: yAxisLabel,
          rotation: yAxisLabelRotation
        },
        ...yAxis
      },
      xAxis: Object.assign({
        type: 'datetime',
        labels: {
          style: {
            textTransform: 'uppercase'
          }
        }
      }, xAxis),
      tooltip,
      plotOptions: _merge({
        series: {
          pointPadding: 0.2,
          lineWidth: 1,
          lineColor: '#fff',
          dataLabels: {
            enabled: false
          },
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        }
      }, plotOptions),
      noData
    }
  }
}

export default AreaChart
