<template>
  <div class="Streams">
    <div class="columns">
      <div class="column is-9">
        <div class="container">
          <div class="columns">
            <div class="column is-3 Streams__sidebar">
              <!-- TODO: temporary disabled by viet. 27 March 2019 -->
              <!-- <template v-if="streams.length">
                <subheading class="is-underlined">{{ $t('pages.streams.stream_list.brand_streams') }}</subheading>

                <div class="Collection columns is-multiline">
                  <div
                    key="brand_streams"
                    class="column is-12"
                  >
                    <brand-stream/>
                  </div>
                </div>
              </template> -->
              <subheading class="is-underlined">
                {{ $t('pages.streams.stream_list.subheading') }}
              </subheading>

              <div>
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <div
                    v-if="streams.length"
                    class="Collection columns is-multiline"
                    data-testid="streams"
                  >
                    <div
                      v-for="(stream) in streams"
                      :key="stream.id"
                      class="column is-12"
                    >
                      <stream-item :stream="stream">
                        <template #stream-metrics>
                          <stream-metrics-chart
                            :chart-data="streamMetrics[stream.id]"
                            :is-active="stream.id === streamId"
                          />
                        </template>
                      </stream-item>
                    </div>
                  </div>
                  <stream-placeholder v-else-if="isLoading" />
                </transition>
                <div
                  key="AddAnotherTrigger"
                  class="Streams-AddAnotherTriggerWrapper p-a-m"
                >
                  {{ $t('pages.streams.stream_list.add_another_text') }}
                  <div class="has-text-right m-t-s">
                    <restricted-button
                      :to="{ name:'streams.create' }"
                      permission="stream.create"
                      enable-when-not-authorized
                      action-class="button is-primary"
                      data-testid="StreamsSidebar-CreateStream"
                      @authorized-click="openUpgradeForMoreStreamsDialog"
                      @click.native="sendStreamCreateIntentEvent({ user_streams:true })"
                    >
                      {{ $t('forms.create_stream') }}
                    </restricted-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-9-tablet Streams__content">
              <transition
                name="fade"
                mode="out-in"
              >
                <div v-if="streams.length">
                  <i18n
                    v-if="currentStream && currentStream.quota_exceeded"
                    tag="div"
                    class="notification is-warning"
                    path="pages.streams.stream_list.quota_exceeded"
                  >
                    <router-link slot="link" :to="{ name: 'streams.edit', params: { streamId } }">
                      {{ $t('general.here') }}
                    </router-link>
                  </i18n>
                  <router-view class="mentions" />
                </div>
                <mention-placeholder
                  v-else-if="isLoading"
                  class="m-t-l"
                />
                <div
                  v-else
                  class="message is-warning has-text-muted"
                >
                  <div class="message-body">
                    <i18n path="pages.streams.stream_list.no_stream_created">
                      <strong slot="brand">{{ activeBrand.name }}</strong>
                      <restricted-button
                        slot="link"
                        :to="{ name:'streams.create' }"
                        permission="stream.create"
                        enable-when-not-authorized
                        action-class="button is-unstyled has-text-decoration-underline"
                        @authorized-click="openUpgradeForMoreStreamsDialog"
                        @click.native="sendStreamCreateIntentEvent({ no_streams_created: true })"
                      >
                        {{ $t('forms.create_stream') }}
                      </restricted-button>
                    </i18n>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <stream-sidebar-charts :stream-id="streamId" />
      </div>
    </div>
    <el-dialog
      :visible.sync="showOnboardedWelcomeMessage"
      class="el-dialog--no-title-bg is-fullscreen-mobile"
      width="750px"
    >
      <heading size="2">
        {{ $t('pages.streams.stream_list.onboarded_message.heading') }}
      </heading>
      <div class="m-b-m">
        {{ $t('pages.streams.stream_list.onboarded_message.content') }}
        <round-icon type="pencil" class="has-text-primary" />
      </div>
      <div>
        {{ $t('pages.streams.stream_list.onboarded_message.download_apps') }}
      </div>
      <div>
        {{ $t('pages.streams.stream_list.onboarded_message.enjoy') }}
      </div>
      <div slot="footer">
        <v-button class="is-primary" @click="showOnboardedWelcomeMessage = false">
          {{ $t('forms.ok') }}
        </v-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="isUpgradeDialogVisible"
      class="el-dialog--no-title-bg is-fullscreen-mobile"
      data-testid="StreamsUpgradeDialog"
      width="750px"
    >
      <heading size="2">
        {{ $t('pages.streams.stream_list.upgrade_dialog.heading') }}
      </heading>
      <div class="m-b-m">
        {{ $t('pages.streams.stream_list.upgrade_dialog.subheading') }}
      </div>
      <div class="m-b-l" v-html="$t('pages.streams.stream_list.upgrade_dialog.text')" />
      <router-link
        :to="{ name: 'cx.pricing' }"
        class="button is-primary"
      >
        {{ $t('pages.streams.stream_list.upgrade_dialog.plans') }}
      </router-link>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { StreamsApiService } from '@hypefactors/shared/js/services/api/StreamsService'

import StreamItem from '@/components/streams/StreamItem'
// import BrandStream from '@/components/streams/BrandStream'
import StreamPlaceholder from '@/components/placeholders/StreamPlaceholder'
import MentionPlaceholder from '@/components/placeholders/MentionPlaceholder'
import StreamMetricsChart from '@/components/streams/StreamMetricsChart'
import StreamSidebarCharts from '@hypefactors/shared/js/components/streams/StreamSidebarCharts'

/**
 * Streams Page
 * @module Streams
 */
export default {
  components: {
    StreamSidebarCharts,
    StreamMetricsChart,
    MentionPlaceholder,
    StreamPlaceholder,
    // BrandStream,
    StreamItem
  },

  data: () => ({
    streams: [],
    isLoading: false,
    cancelToken: null,
    showOnboardedWelcomeMessage: false,
    streamMetrics: [],
    isUpgradeDialogVisible: false
  }),

  computed: {
    ...mapGetters(['activeBrand', 'currentUser']),

    streamId () {
      return this.$route.params.streamId
    },

    isCurrentStreamIdRelevant () {
      if (!this.streamId) {
        return false
      }
      // return this.streamId === 'brand_mentions' || (this.streams.findIndex(stream => stream.id === this.streamId) !== -1)
      return (this.streams.findIndex(stream => stream.id === this.streamId) !== -1)
    },

    currentStream () {
      return this.streamId ? this.streams.find(s => s.id === this.streamId) : undefined
    }
  },

  watch: {
    $route () {
      if (!this.streamId) {
        this.selectDefaultStream()
      }
    },

    activeBrandId: {
      handler: 'loadData',
      immediate: true
    }
  },

  mounted () {
    if (this.$route.query.onboarded) {
      this.showOnboardedWelcomeMessage = true
      this.$ma.trackEvent({
        action: 'Signed Up',
        properties: {
          type: 'Free Media Monitoring',
          firstName: this.currentUser.first_name,
          lastName: this.currentUser.last_name,
          email: this.currentUser.email,
          phone: this.currentUser.phone,
          occupation: this.currentUser.role
        }
      })
    }
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    loadData () {
      this.loadStreams()
      this.loadStreamMetrics()
    },

    loadStreams () {
      this.cancelToken && this.cancelToken.cancel()
      this.streams = []
      this.isLoading = true
      this.cancelToken = this.$api.cancelToken()
      StreamsApiService.fetchAll({
        params: {
          brands: [this.activeBrandId]
        },
        cancelToken: this.cancelToken.token
      })
        .then(streams => {
          this.isLoading = false
          this.streams = streams
          this.selectDefaultStream()
        })
        .catch(e => {
          this.isLoading = false
          if (this.$api.isCancelToken(e)) {
            return
          }
          this.$displayRequestError(e, this.$t('errors.cannot_fetch_streams'))
        })
    },

    async loadStreamMetrics () {
      this.streamMetrics = await StreamsApiService.fetchStreamMetrics(this.activeBrandId)
    },

    selectDefaultStream () {
      // Don't redirect to default stream if we are already on one
      if (this.isCurrentStreamIdRelevant || !this.streams.length) return

      // this.$router.push({ name: 'streams.mentions', params: { streamId: 'brand_mentions' } })
      // let route = { name: 'streams.list' }

      const id = this.streams[0].id
      this.$router.push({ name: 'streams.mentions', params: { streamId: id } })
    },

    openUpgradeForMoreStreamsDialog (isAuthorized) {
      if (isAuthorized) return
      this.isUpgradeDialogVisible = true
    },

    sendStreamCreateIntentEvent (props = {}) {
      this.$ma.trackEvent({
        action: 'Stream Creation Intent',
        properties: {
          isFromNavigation: false,
          ...props
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Streams-AddAnotherTriggerWrapper {
  border: 1px dashed $dark;
}

@include tablet {
  .Streams {
    .mentions {
      margin-top: $margin-small
    }
  }
}
</style>
