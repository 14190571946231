<template>
  <div class="StreamSidebarCharts border-grey border-style-dashed">
    <stream-metrics-data-provider
      #default="{ mentionsTimeseries, topMediaOutletsByType, totalImpressions }"
      :stream-id="streamId"
      :stream-body="streamBody"
    >
      <mentions-over-period-chart :chart-data="mentionsTimeseries" />
      <v-separator dashed class="m-b-l" />
      <mentions-summaries :timeseries="mentionsTimeseries" :total-impressions="totalImpressions[0]" />
      <v-separator dashed class="m-t-l" />
      <media-outlets-chart :chart-data="topMediaOutletsByType" />
    </stream-metrics-data-provider>
  </div>
</template>

<script>
import MentionsOverPeriodChart from './MentionsOverPeriodChart'
import StreamMetricsDataProvider from './StreamMetricsDataProvider'
import MentionsSummaries from './MentionsSummaries'
import MediaOutletsChart from './MediaOutletsChart'

/**
 * @module StreamSidebarCharts
 */
export default {
  name: 'StreamSidebarCharts',
  components: {
    MediaOutletsChart,
    MentionsSummaries,
    StreamMetricsDataProvider,
    MentionsOverPeriodChart
  },
  props: {
    streamId: {
      type: String,
      default: ''
    },
    streamBody: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
