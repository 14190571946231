import Chart from './Chart'
import * as chartColors from '../DefaultChartColors'

class BarChart extends Chart {
  constructor (ref, categories, series, options) {
    super(ref, series)

    this.categories = categories
    this.setOptions(this.barChartOptions(options))

    this.init()
  }

  barChartOptions ({ title, formatter, dataLabels, tooltipFormatter, monochrome, height = null, yAxisLabel, pointWidth, colors, plotOptions, marginLeft, marginRight, chart = {}, xAxis, yAxis }) {
    dataLabels = Object.assign({
      enabled: true,
      align: 'left',
      defer: true
    }, dataLabels)

    if (formatter) {
      dataLabels.formatter = formatter
    }

    let tooltip = {}

    if (tooltipFormatter) {
      tooltip.formatter = tooltipFormatter
    }

    return {
      chart: {
        type: 'bar',
        height,
        marginLeft,
        marginRight,
        ...chart
      },
      colors,
      title: {
        text: title,
        align: 'left',
        style: { fontSize: '1.25rem', fontWeight: '400' }
      },
      yAxis: {
        allowDecimals: false,
        maxPadding: 0.1,
        title: {
          text: yAxisLabel
        },
        labels: {
          style: {
            fontWeight: 700,
            color: chartColors.charcoal
          }
        },
        ...yAxis
      },
      xAxis: {
        categories: this.categories,
        type: 'category',
        labels: {
          style: {
            fontWeight: 700,
            color: chartColors.charcoal
          }
        },
        ...xAxis
      },
      legend: false,
      tooltip,
      plotOptions: {
        series: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels,
          pointWidth
        },
        bar: {
          color: monochrome ? chartColors.charcoal : undefined,
          colorByPoint: !monochrome
        },
        ...plotOptions
      }
    }
  }
}

export default BarChart
