<template>
  <content-loader
    :height="130"
    :width="410"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
    class="has-background-white p-a-m max-width-full"
  >
    <circle cx="40" cy="40" r="38" />
    <rect x="88" y="16" rx="3" ry="3" width="240" height="10" />
    <rect x="88" y="50" rx="3" ry="3" width="175" height="10" />
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
