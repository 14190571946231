<template>
  <div class="MentionsSummaries">
    <div class="columns is-multiline has-text-centered">
      <div class="column">
        <div class="is-uppercase is-size-sm">
          {{ $t('components.mentions_metrics_summaries.articles') }}
        </div>
        <div class="is-size-2 has-text-weight-bold">
          {{ totalMentions }}
        </div>
      </div>
      <div class="column">
        <div class="is-uppercase is-size-sm">
          {{ $t('components.mentions_metrics_summaries.impressions') }}
        </div>
        <div class="is-size-2 has-text-weight-bold">
          {{ totalImpressions }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module MentionsSummaries
 */
export default {
  name: 'MentionsSummaries',
  props: {
    timeseries: {
      type: Object,
      required: true
    },
    totalImpressions: {
      type: Number,
      required: true
    }
  },
  computed: {
    totalMentions () {
      return Object.values(this.timeseries)
        .reduce((all, current) => all + current, 0)
    }
  }
}
</script>
