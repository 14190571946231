<template>
  <v-button
    :loading="isLoading"
    :disabled="isLoading"
    :class="{ 'StreamFollowButton--Following': isFollowing, [`is-${color}`]: color }"
    :title="$t(isFollowing ? 'components.stream_follow_button.following': 'components.stream_follow_button.follow')"
    class="StreamFollowButton is-plain is-narrow"
    data-testid="StreamFollowButton"
    @click="toggleFollowStatus"
  >
    {{ $t('components.stream_follow_button.'+ (isFollowing ? 'following': 'follow')) }}
  </v-button>
</template>

<script>

/**
 * Allows toggling a Stream's following status
 * @module StreamFollowButton
 */
import { StreamsApiService } from '@hypefactors/shared/js/services/api/StreamsService'

export default {
  name: 'StreamFollowButton',
  model: {
    prop: 'isFollowing',
    event: 'change'
  },
  props: {
    isFollowing: {
      type: Boolean,
      default: false
    },
    streamId: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'black'
    }
  },
  data () {
    return {
      isLoading: false,
      cancelToken: null
    }
  },
  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },
  methods: {
    async toggleFollowStatus () {
      try {
        this.isLoading = true
        this.cancelToken = this.$api.cancelToken()
        if (this.isFollowing) {
          await this.unfollow()
        } else {
          await this.follow()
        }
        this.$emit('change', !this.isFollowing)
      } catch (err) {
        if (this.$api.isCancelToken(err)) return
        this.$displayRequestError(err)
      } finally {
        this.isLoading = false
      }
    },
    async follow () {
      await StreamsApiService.follow(this.streamId, {
        cancelToken: this.cancelToken.token
      })
      this.$notify.success(this.$t('components.stream_follow_button.stream_followed'))
    },
    async unfollow () {
      await StreamsApiService.unfollow(this.streamId, {
        cancelToken: this.cancelToken.token
      })
      this.$notify.success(this.$t('components.stream_follow_button.stream_unfollowed'))
    }
  }
}
</script>
