<template>
  <div ref="chart" class="StreamMetricsChart is-full-height" />
</template>

<script>
/**
 * @module StreamMetricsChart
 */
import AreaChart from '@hypefactors/shared/js/components/charts/base/AreaChart'
import Highcharts from 'highcharts'
import { parse } from 'date-fns'

export default {
  name: 'StreamMetricsChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Returns the primary color of the stream item
     * @returns {string}
     */
    color () {
      return this.isActive ? '#ffffff' : '#000'
    }
  },
  watch: {
    chartData: 'redrawChart'
  },
  mounted () {
    this.initChart()
  },
  methods: {
    initChart () {
      this.$chart = new AreaChart(
        this.$refs.chart,
        this.createSeries(),
        {
          // remove labels
          yAxis: {
            visible: false
          },
          xAxis: {
            visible: false
          },
          title: { text: '' },
          chart: {
            // make chart flush
            margin: [0, -2, 0, -2],
            // remove BG
            backgroundColor: ''
          },
          plotOptions: {
            series: {
              // removes hover
              enableMouseTracking: false,
              // add gradient
              fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, this.color],
                  [0.6, Highcharts.Color(this.color).setOpacity(0.4).get('rgba')],
                  [1, Highcharts.Color(this.color).setOpacity(0).get('rgba')]
                ]
              }
            }
          },
          tooltip: { enabled: false },
          noData: {
            style: {
              color: this.color
            }
          }
        })
    },
    createSeries () {
      return [{
        showInLegend: false,
        marker: {
          enabled: false
        },
        dataLabels: { enabled: false },
        name: 'Stream Metrics',
        color: this.color,
        data: this.chartData.map((data) => [parse(data.date).getTime(), data.count])
      }]
    },
    redrawChart () {
      this.$chart.update(this.createSeries())
    }
  }
}
</script>
