<template>
  <content-loader
    :height="130"
    :width="380"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
    class="has-background-white p-a-m max-width-full"
  >
    <rect x="0" y="0" rx="3" ry="3" width="140" height="4" />
    <rect x="0" y="10" rx="3" ry="3" width="120" height="4" />
    <rect x="320" y="20" rx="3" ry="3" width="60" height="70" />
    <rect class="middleTitle" x="0" y="25" rx="3" ry="3" width="100" height="8" />
    <rect class="middleText" x="0" y="50" rx="3" ry="3" width="200" height="6" />
    <rect class="middleText" x="0" y="60" rx="3" ry="3" width="186" height="6" />
    <rect class="bottomSquare" x="0" y="100" rx="0" ry="0" width="45" height="15" />
    <rect class="bottomSquare" x="55" y="100" rx="0" ry="0" width="45" height="15" />
    <rect class="bottomSquare" x="110" y="100" rx="0" ry="0" width="45" height="15" />
    <rect class="bottomSquare" x="165" y="100" rx="0" ry="0" width="45" height="15" />
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
