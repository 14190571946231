<template>
  <div v-loading="isLoading">
    <slot v-bind="{ mentionsTimeseries, topMediaOutletsByType, totalImpressions }" />
  </div>
</template>
<script>
import { StreamsMetricsApiService } from '@hypefactors/shared/js/services/api/StreamsMetricsApiService'
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'

export default {
  name: 'StreamMetricsDataProvider',
  mixins: [debounceMixin(['fetchData'])],
  props: {
    streamId: {
      type: String,
      default: ''
    },
    streamBody: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isLoading: false,
      mentionsTimeseries: {},
      topMediaOutletsByType: {
        categories: [],
        data: []
      },
      totalImpressions: [0],
      cancelToken: null
    }
  },
  computed: {
    requestBody () {
      return this.streamId
        ? { stream_id: this.streamId }
        : this.streamBody
    }
  },
  watch: {
    streamId: {
      handler (streamId, oldId) {
        if (streamId) {
          this.fetchData()
        }
      },
      immediate: true
    },
    streamBody () {
      this.fetchData()
    }
  },
  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },
  methods: {
    async fetchData () {
      if (!Object.keys(this.requestBody).length) return

      try {
        this.isLoading = true

        this.cancelToken && this.cancelToken.cancel()
        this.cancelToken = this.$api.cancelToken()

        await Promise.all([
          this.fetchTimeseriesByMentions(),
          this.fetchTopMediaOutletsByMediaTypes(),
          this.fetchTotalImpressions()
        ])
      } catch (e) {
        if (this.$api.isCancelToken(e)) return
        this.$displayRequestError(e)
      } finally {
        this.isLoading = false
      }
    },
    async fetchTimeseriesByMentions () {
      this.mentionsTimeseries = await StreamsMetricsApiService.fetchTimeseriesByMentions(this.requestBody, { cancelToken: this.cancelToken.token })
    },
    async fetchTopMediaOutletsByMediaTypes () {
      this.topMediaOutletsByType = await StreamsMetricsApiService.fetchTopMediaOutletsByMediaTypes(this.requestBody, { cancelToken: this.cancelToken.token })
    },
    async fetchTotalImpressions () {
      this.totalImpressions = await StreamsMetricsApiService.fetchTotalImpressions(this.requestBody, { cancelToken: this.cancelToken.token })
    }
  }
}
</script>
