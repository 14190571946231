import { openWindow, pollPopupForParameter } from '@/utils'
import { mapGetters, mapActions } from 'vuex'
import { getUrlToDomain } from '@hypefactors/shared/js/utils'

export default {
  computed: {
    ...mapGetters('social', ['isSocialConnectedToBrand', 'getSocialProviderData'])
  },
  methods: {
    ...mapActions({ refreshSocialNetworks: 'fetchUserBrandsRequest' }),
    /**
     * Checks if the media requires a social token
     * @param {String | Object} media
     * @return {*}
     */
    requiresToken (media) {
      if (typeof media === 'string') {
        // TODO: should check if connected to brand not to user
        return !this.isSocialConnectedToBrand(media)
      }
      return media.requires_oauth && !this.isSocialConnectedToBrand(media.id)
    },
    requiresSocialTokenForSomeNetworks (media) {
      return false
    },
    /**
     * Requests a social token from a provider
     * @param {String} media - The social media provider to authenticate
     * @return {Promise<void>}
     */
    async requestSocialToken (media) {
      const url = getUrlToDomain(`oauth/social/${media}/authorize`, 'api')
      const loading = this.$loading({
        lock: true,
        text: 'Waiting for token',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        await pollPopupForParameter(openWindow(url), { search: `?token_received=true&media=${media}` })
      } catch (err) {
        loading.close()
        this.$notify.error(this.$t('components.socials.social_token_manager.window_closed_early', { media }))
        // throw the error again so we exit
        throw err
      }
      try {
        await this.refreshSocialNetworks()
        loading.close()
        this.$notify.success(this.$t('success.successfully_connected'))
      } catch (err) {
        let skipReport = false
        if (err.message && err.message.includes('User closed window')) {
          skipReport = true
        }
        this.$handleError(err, this.$t('errors.error'), 5000, skipReport)
        loading.close()
        // rethrow the error so we know there was an issue with refetching user
        throw err
      }
    },
    async revokeSocialToken (media) {
      const url = this.getSocialProviderData(media)['deauthorize_url']
      if (!url) return
      await this.$api.delete(url)
      return this.refreshSocialNetworks()
    }
  }
}
