<template>
  <component
    :is="isActive ? 'div': 'router-link'"
    v-loading="isSubmitting"
    :to="{ name: 'streams.mentions', params: { streamId: stream.id }}"
    :class="{ 'is-active': isActive }"
    class="Stream-item Card"
    data-testid="StreamItem"
  >
    <el-tooltip
      :content="$t('components.streams.manage_medias.requires_authorization')"
      effect="dark"
      placement="top-start"
    >
      <restricted-button
        v-show="streamRequiresSocialAuth"
        :title="$t('forms.edit')"
        :to="{ name: 'streams.edit', params: { streamId: stream.id } }"
        permission="stream.update"
        tag="a"
        action-class="requiresSocialToken"
      >
        <div class="icon is-round has-background-danger is-size-7">
          !
        </div>
      </restricted-button>
    </el-tooltip>
    <div class="Card__header">
      <div data-testid="streamName">
        <div class="is-flex is-aligned-middle can-wrap">
          <v-icon
            v-if="stream.quota_exceeded"
            type="exclamation-triangle"
            class="m-r-s"
            :title="$t('components.streams.stream_item.quota_exceeded')"
          />
          <span class="has-text-weight-semibold is-size-5">{{ stream.title }}</span>
          <div v-show="stream.auto_clipping" class="m-l-s">
            | {{ $t('components.streams.stream_item.auto_clipped') }}
          </div>
          <a
            v-if="stream.auto_rss"
            :href="rssFeed"
            :title="$t('components.streams.stream_item.rss_feed')"
            target="_blank"
            class="m-l-a Stream-item__RSS"
          >
            <v-icon type="feed" />
          </a>
        </div>
        <div class="opacity-60">
          {{ mediaTypes }}
        </div>
      </div>
    </div>
    <div class="is-expanded">
      <div class="Stream-item__chart is-full-height">
        <slot name="stream-metrics" />
      </div>
      <div
        v-if="isActive"
        class="Stream-item__actions columns is-variable-grid is-2 is-multiline is-mobile is-aligned-justify is-aligned-middle is-marginless"
      >
        <div class="column is-narrow is-flex is-aligned-center">
          <router-link
            v-if="showUpdateStreamLink"
            :title="$t('components.streams.stream_item.edit_button_title')"
            :to="{ name: 'streams.edit', params: { streamId: stream.id } }"
            class="Stream-item__action Stream-item__edit button is-narrow is-plain is-white"
            data-testid="StreamItemEditAction"
            @click.native="trackEditIntent"
          >
            {{ $t('components.streams.stream_item.edit_stream') }}
          </router-link>
        </div>
        <!-- <div class="column is-narrow">
          <stream-follow-button
            v-model="stream.followed_by_user"
            :color="isActive ? 'white': 'black'"
            :stream-id="stream.id"
          />
        </div> -->
      </div>
    </div>
  </component>
</template>

<script>
import SocialTokenManagerMixin from '@/components/social/SocialTokenManagerMixin'
import StreamFollowButton from '@/components/streams/StreamFollowButton'
import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

export default {
  components: { StreamFollowButton },
  mixins: [SocialTokenManagerMixin],

  props: {
    /**
     * @type {HF_Stream}
     */
    stream: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    isSubmitting: false
  }),

  computed: {
    isActive () {
      return this.stream.id === this.$route.params.streamId
    },

    showUpdateStreamLink () {
      return this.authorizeBool('stream.update')
    },

    streamRequiresSocialAuth () {
      return this.stream.source_types.some(this.requiresSocialTokenForSomeNetworks) && this.authorizeBool('clipping.create')
    },

    mediaTypes () {
      return this.stream.source_types.map((type) => this.$t(MEDIA_TYPES[type].label)).join(', ')
    },

    rssFeed () {
      return this.$getUrl(`/streams/${this.stream.id}/rss`, 'api')
    }
  },

  methods: {
    trackEditIntent () {
      this.$ma.trackEvent({
        action: 'Stream Edit Intent',
        properties: {
          streamName: this.stream.title,
          streamId: this.stream.id
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Stream-item {
  @include shadow-1();

  background-color: $hf__color-white !important;
  color: $hf__color-black !important;
  position: relative;
  display: flex;
  flex-flow: column;

  &.is-active {
    color: $white !important;
    background-color: $hf__color-black !important;
    min-height: 150px;

    a {
      color: $white;
    }

    .requiresSocialToken .icon {
      background: $dark;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__chart {
    height: 80px;
  }

  .Card__header {
    padding: $padding-small;

    .Stream-item__actions {
      margin-top: $margin;
      max-width: none;
    }
  }

  .requiresSocialToken {
    position: absolute;
    top: -5px;
    right: -5px;
  }
}
</style>
