<template>
  <div ref="chart" class="MediaOutletsChart" />
</template>

<script>
import BarChart from '@hypefactors/shared/js/components/charts/base/BarChart'
import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

/**
 * @module MediaOutletsChart
 */
export default {
  name: 'MediaOutletsChart',
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  computed: {
    categories () {
      return this.chartData.categories
        .map((outlet) => this.$t(MEDIA_TYPES[outlet].label))
    }
  },
  watch: {
    chartData: 'updateChart'
  },
  mounted () {
    this.createChart()
  },
  methods: {
    createChart () {
      this.$chart = new BarChart(
        this.$refs.chart,
        {},
        this.createSeries(),
        {
          title: 'Media Outlets',
          chart: {
            backgroundColor: 'transparent'
          },
          pointWidth: 20,
          plotOptions: {
            bar: {
              color: '#000'
            }
          },
          yAxis: {
            labels: {
              enabled: false
            }
          },
          xAxis: {
            lineWidth: 0
          }
        }
      )
    },
    updateChart () {
      this.$chart.update(this.createSeries(), null, this.categories)
      const height = (this.categories.length || 2) * 35 + 30 // if we dont have any categories, make it 2 bars high
      this.$chart.instance.setSize(null, height, false)
    },
    createSeries () {
      return [{
        data: this.chartData.data,
        name: this.$t('components.media_outlets_chart.number_of_outlets')
      }]
    }
  }
}
</script>
