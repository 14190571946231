<template>
  <div ref="chart" class="MentionsOverPeriodChart" />
</template>

<script>
import AreaChart from '@hypefactors/shared/js/components/charts/base/AreaChart'
import { lightGrey } from '@hypefactors/shared/js/components/charts/DefaultChartColors'

/**
 * @module MentionsOverPeriodChart
 */
export default {
  name: 'MentionsOverPeriodChart',
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  watch: {
    chartData: 'updateChart'
  },
  mounted () {
    this.createChart()
  },
  methods: {
    createChart () {
      this.$chart = new AreaChart(this.$refs.chart, this.createSeries(), {
        chart: {
          backgroundColor: 'transparent',
          height: 200
        },
        legend: {
          enabled: false
        },
        title: {
          text: this.$t('components.mentions_over_period_chart.mentions_over_time')
        },
        yAxis: {
          labels: {
            enabled: false
          },
          gridLineColor: lightGrey
        },
        xAxis: {
          labels: {
            enabled: false
          }
        },
        plotOptions: {
          series: {
            lineColor: '#000'
          }
        }
      })
    },
    createSeries () {
      return [{
        data: Object.entries(this.chartData).map(([timestamp, value]) => [parseInt(timestamp) * 1000, value]),
        name: this.$t('general.mentions'),
        color: '#000'
      }]
    },
    updateChart () {
      this.$chart.update(this.createSeries())
    }
  }
}
</script>
