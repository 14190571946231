import { api } from './ApiInstanceInjector'

export const UPDATE_ENDPOINT = (streamId) => `/streams/${streamId}`
export const FETCH_STREAM_MENTIONS_ENDPOINT = (streamId) => `streams/${streamId}/mentions`
export const FETCH_BRAND_MENTIONS_ENDPOINT = (brandId) => `brands/${brandId}/mentions`
export const TRANSFER_OWNERSHIP_ENDPOINT = (streamId) => `/streams/${streamId}/transfer-ownership`
export const HIDE_MENTION_ENDPOINT = '/streams/hide-mention'
export const UNHIDE_MENTION_ENDPOINT = '/streams/unhide-mention'
export const DELETE_STREAM_ENDPOINT = (id) => `streams/${id}`
export const FOLLOW_STREAM_ENDPOINT = (streamId) => `/streams/${streamId}/subscribe`
export const UNFOLLOW_STREAM_ENDPOINT = (streamId) => `/streams/${streamId}/unsubscribe`
export const STREAM_METRICS = '/streams/metrics/count'

export const StreamsApiService = {
  fetchAll (options) {
    return api.getData('streams', options)
  },
  get (streamId, options) {
    return api.getData(`streams/${streamId}`, options)
  },
  create (payload) {
    return api.post('streams?include=brand', payload)
  },
  update (id, payload) {
    return api.patch(UPDATE_ENDPOINT(id), payload)
  },
  transferOwnership (id, brand) {
    return api.post(TRANSFER_OWNERSHIP_ENDPOINT(id), {
      brand
    })
  },

  /**
   * Deletes a stream
   * @param {string} id
   * @return {Promise}
   */
  delete (id) {
    return api.delete(DELETE_STREAM_ENDPOINT(id))
  },

  fetchStreamMetrics (brandId) {
    return api.getData(STREAM_METRICS, {
      params: {
        brands: [brandId]
      }
    })
  },

  /**
   * Hides a mention from a stream
   * @param {HF_HideMentionPayload} rawPayload - Mention ES id and the level of hiding
   * @param {string} level
   * @returns {Promise<any>}
   */
  hideMention (level, rawPayload) {
    return api.post(HIDE_MENTION_ENDPOINT, getHidePayload(level, rawPayload))
  },

  /**
   * Unhides a mention from a stream
   * @param {HF_HideMentionPayload} rawPayload - Mention ES id and the level of hiding
   * @param {string} level
   * @returns {Promise<any>}
   */
  unhideMention (level, rawPayload) {
    return api.post(UNHIDE_MENTION_ENDPOINT, getHidePayload(level, rawPayload))
  },

  follow (streamId, options) {
    return api.post(FOLLOW_STREAM_ENDPOINT(streamId), options)
  },
  unfollow (streamId, options) {
    return api.delete(UNFOLLOW_STREAM_ENDPOINT(streamId), options)
  }
}

/**
 * @typedef {Object} HF_HideMentionPayload
 * @property {string} esId - Mention Elastic Search Id
 * @property {string} level - The level to hide for, (brand, organisation, stream)
 * @property {string} [organisationId]
 * @property {string} [streamId]
 * @property {string} [brandId]
 */

/**
 * Constructs the HideMentionPayload by the required level
 * @param {string} level
 * @param {HF_HideMentionPayload} payload
 */
function getHidePayload (level, payload) {
  const properPayload = {
    es_id: payload.esId,
    level: payload.level
  }
  if (level === 'stream') {
    properPayload.stream_id = payload.streamId
  } else if (level === 'organisation') {
    properPayload.organisation_id = payload.organisationId
  } else {
    properPayload.brand_id = payload.brandId
  }
  return properPayload
}
