import { api } from './ApiInstanceInjector'

export const StreamsMetricsApiService = {
  fetchTimeseriesByMentions (body, params) {
    return api.postData('metrics/streams/timeseries/mentions', body, params)
  },
  fetchTopMediaOutletsByMediaTypes (body, params) {
    return api.postData('metrics/streams/top/media-outlets/by-media-types', body, params)
  },
  fetchTotalImpressions (body, params) {
    return api.postData('metrics/streams/total/impressions', body, params)
  }
}
